<template>
  <div>
    <currentLocation :sibItem="sibItem"></currentLocation>
    <div class="outer">
      <div class="BItip">{{ $t("memorabilia.mb_tip") }}</div>
      <div class="notes">
        <div class="lateral_sliding" @mousewheel="handleScroll" @mouseover="changeActive()" @mouseout="removeActive()">
          <div :class="'notesItem notesItem' + index" v-for="(item, index) in notes" :key="index">
            <div class="indexTwo">
              <div class="itemOne">
                <div class="itemLeft">
                  <div class="leftTimeXian">
                    <div class="time" v-html="item.datetime"></div>
                    <div class="hengxian"></div>
                  </div>
                  <img src="@/assets/img/dashiji2.png" alt="" class="xian" />
                </div>
                <div class="itemRight">
                  <div class="itemImg">
                    <img :src="item.image" alt="" class="imgHoverBig" />
                  </div>
                  <div class="title">
                    <div>{{ item.title }}</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div v-if="index % 2 == 0" class="indexOne">
              <div class="itemOne">
                <div class="itemLeft">
                  <img src="@/assets/img/dashiji.png" alt="" class="xian" />
                  <div class="leftTimeXian">
                    <div class="time" v-html="item.datetime"></div>
                    <div class="hengxian"></div>
                  </div>
                </div>
                <div class="itemRight">
                  <div class="title">
                    <div>{{ item.title }}</div>
                  </div>
                  <img :src="item.image" alt="" class="itemImg" />
                </div>
              </div>
            </div>

            <div v-if="index % 2 != 0" class="indexTwo">
              <div class="itemOne">
                <div class="itemLeft">
                  <div class="leftTimeXian">
                    <div class="time" v-html="item.datetime"></div>
                    <div class="hengxian"></div>
                  </div>
                  <img src="@/assets/img/dashiji2.png" alt="" class="xian" />
                </div>
                <div class="itemRight">
                  <img :src="item.image" alt="" class="itemImg" />
                  <div class="title">
                    <div>{{ item.title }}</div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：关于我们-发展大事记
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-12 17:32
 */
import currentLocation from "@/components/currentLocation.vue";
export default {
  components: {
    currentLocation,
  },
  data() {
    return {
      sibItem: [
        {
          title_zh: "关于我们",
          title_en: "About us",
          path: "aboutUs",
        },
        {
          title_zh: "发展大事记",
          title_en: "Development Memorabilia",
          path: "",
        },
      ],

      items: 30,

      notes: [],
    };
  },
  mounted() {
    this.getHistoryList();
  },
  methods: {
    /**
     * 大图
     * 刘嘉鑫
     * 2022-8-19
     */
    getHistoryList() {
      this.$request({
        url: "/Index/historyList",
        data: {},
      }).then((res) => {
        console.log("发展大事记", res);
        for (let i in res.history_list) {
          res.history_list[i].datetime = res.history_list[i].datetime.replace(
            /年/g,
            "年<br/>"
          );
        }
        this.notes = res.history_list;
      });
    },
    handleScroll(e) {
      // 0----down  1----up
      const tabBar = this.$el.querySelector(".lateral_sliding");
      //下面的实现的是内部元素横向滚动，前提设置好内部元素横向的滚动样式了
      let leftScroll = tabBar.scrollLeft;
      // 0----down  1----up
      if (e.deltaY == -0) {
        var direction = e.deltaX > 0 ? "0" : "1";
        if (direction === "0") {
          tabBar.scrollLeft += 10;
        } else {
          tabBar.scrollLeft -= 10;
        }
      } else {
        var direction = e.deltaY > 0 ? "0" : "1";
        if (direction === "0") {
          tabBar.scrollLeft += 50;
        } else {
          tabBar.scrollLeft -= 50;
        }
      }

      if (leftScroll == tabBar.scrollLeft) {
        // console.log(tabBar.scrollLeft, e);
        this.removeActive();
      }
    },
    /**
     * 鼠标移入禁止页面滚动
     * 刘嘉鑫
     * 2022-8-20
     */
    changeActive() {
      document.querySelector("body").style.overflow = "hidden";
    },
    /**
     * 鼠标移出页面滚动
     * 刘嘉鑫
     * 2022-8-20
     */
    removeActive() {
      document.querySelector("body").style.overflow = "";
    },
  },
};
</script>
<style lang='scss' scoped>
.outer {
  padding: 60px 0;
  background: #1a2a60;
  overflow: hidden;
}

.active {
  overflow-y: hidden;
}

.BItip {
  font-size: 30px;
  font-family: FZFengYaSongS-GB;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 78px;
  padding: 0 310px;
}

.notes {
  // height: 1000px;
}

.lateral_sliding {
  display: flex;
  align-items: baseline;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
}

.notesItem {
  position: relative;
  padding-right: 80px;

  .hengxian {
    position: absolute;
    // bottom: 14%;
    // right: 0;
    width: 100%;
    height: 1px;
    background: #fefefe;
    opacity: 0.5;
    z-index: 1;
  }

  .time {
    width: 80px;
    height: 50px;
    background: #ffffff;
    position: relative;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #1a2a60;
    margin: 25px 0;
    text-align: center;
    z-index: 10;
  }

  .time:before {
    content: "";
    position: absolute;
    top: -25px;
    left: 0;
    width: 0;
    height: 0;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
    border-bottom: 25px solid #ffffff;
  }

  .time:after {
    content: "";
    position: absolute;
    bottom: -25px;
    left: 0;
    width: 0;
    height: 0;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
    border-top: 25px solid #ffffff;
  }

  .xian {
    width: 40px;
    height: 120px;
    padding-left: 40px;
    object-fit: inherit !important;
  }

  .itemLeft {
    margin-top: 300px;
  }

  .leftTimeXian {
    display: flex;
    align-items: center;
  }

  .itemOne {
    display: flex;

    >.itemRight {
      .title {
        width: 240px;
        margin-bottom: 20px;
        position: relative;
        // height: 200px;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        word-break: break-all;
        white-space: normal !important;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;

        >div {
          // position: absolute;
          // bottom: 0;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          line-height: 28px;
        }
      }

      .itemImg {
        width: 240px;
        height: 150px;
        overflow: hidden;

        >img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .indexTwo {

    // padding-top: 100%;
    .itemOne {
      .itemLeft {
        margin-top: 0 !important;
        margin-bottom: 300px !important;
      }

      >.itemRight {
        padding-bottom: 0 !important;
        padding-top: 160px !important;

        .title {
          >div {
            top: 0 !important;
            bottom: inherit !important;
          }
        }
      }
    }

    .title {
      margin-bottom: 0 !important;
      margin-top: 20px;
    }
  }

  .indexOne {
    padding: 0 0 30px 0;
  }
}

.notesItem0 {
  margin-left: 310px;
}

.notesItem:last-child {
  // padding-right: 310px;
}
</style>